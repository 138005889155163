<div class="container sp-content-project-list" id="containerParent">
  <div class="pt-4 d-flex sp-project-list row" id="stepProject">
    <div class="sp-search-project">
      <div [matTooltipDisabled]="hasEnoughCredits && canCreateProject" matTooltip="{{!hasEnoughCredits ? 'You have no credits left, if you want additional credits or to upgrade your offer, contact us.' : '' || !facebookConnected ? 'You are logged out of Facebook, in order to start a new analysis you must log back into your Facebook account.' : '' || auth.session.user.ad_accounts.length > 0 ? 'Create a new project' : 'You don\'t have any ads account. Go to my profile (at the bottom left of the screen ) and click on the “Update token” in the My Facebook account box.'}}" matTooltipHideDelay="1000">
        <button (click)="newProjectRedirect()" [disabled]="!hasEnoughCredits || !canCreateProject " class="w-100 btn btn-primary sp-btn sp-btn-primary sp-btn-project" id="btn-project" style="min-height: 21.5rem;max-height: 21.5rem;" type="button" >
          <i class="icon-sp-add"></i><br>
          <span class="sp-btn-project-label">New project</span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="projectAndFolderTypeFiltering.includes('folder') || projectAndFolderTypeFiltering.includes('folder_custom')">
      <ng-container *ngFor="let folder of folders; let index = index">
        <div *ngIf="!!folder.flag ? projectAndFolderTypeFiltering.includes('folder_custom') : projectAndFolderTypeFiltering.includes('folder')" class="sp-col-folder">
          <div (click)="selectFolder(folder)" class="sp-card sp-card-1" id="step2">
            <div class="card ">
              <div class="card-header">
                <div class="row pt-3 d-flex no-gutters">
                  <div (click)="$event.stopPropagation()" class="col">
                    <input #title (blur)="onFolderTitleUpdate(title.value, folder); isInsideName = false" (keyup.enter)="title.blur()" [hidden]="!isTitleEditable(title)" [value]="folder.name" class="sp-project-name" id="project-{{folder.id}}" matTooltip="{{folder.name}}" matTooltipHideDelay="10">
                    <div #subFolderTitle (click)="editTitle(title)" [hidden]="isTitleEditable(title)" class="sp-project-name" matTooltip="{{folder.name}}" matTooltipHideDelay="10">{{ folder.name }}</div>
                  </div>
                  <div (click)="$event.stopPropagation()" class="col text-right">
                    <mat-icon [matMenuTriggerFor]="menuFolder" class="sp-project-menu">more_vert</mat-icon>
                    <mat-menu  #menuFolder="matMenu" class="sp-menu-preset">
                      <div class="row">
                        <button (click)="subFolderTitle.click()" mat-menu-item><i class="icon-sp-edit-black"></i><span class="pl-4">Rename</span></button>
                      </div>
                      <div class="row">
                        <button (click)="openDeleteFolderDialog(folder)" mat-menu-item ><i class="icon-sp-delete"></i><span class="pl-4">Delete</span></button>
                      </div>
                      <div *ngIf="folder.flag" (click)="exportFolderCsv(folder)" class="row">
                        <button mat-menu-item><span class="material-icons">
                          upgrade
                        </span><span class="pl-4">Export CSV</span></button>
                      </div>
                      <div *ngIf="folder.flag" class="row">
                        <button (click)="openRefreshFolderDialog(folder)" [disabled]="" mat-menu-item><i class="icon-sp-back"></i><span class="pl-4">Refresh Folder</span></button>
                      </div>
                    </mat-menu>
                  </div>
                </div>
                <div class="row d-flex no-gutters">
                  <div class="col sp-card-project-btn text-right">
                    <button class="btn btn-primary ml-2 rounded-circle sp-btn sp-btn-primary sp-btn-circle" matTooltip="Open" matTooltipHideDelay="10" type="button">
                    <span class="sp-icon">
                      <i (click)="selectFolder(folder)" class="icon-sp-folder-2"></i>
                    </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="ml-4 mr-4 card-body">
                <div class="sp-card-folder">
                  <div class="row d-flex no-gutters ">
                    <div class="col pb-1">
                      <h5 *ngFor="let project of folder.projects" class="pl-3 sp-project">{{ project.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row pb-2 d-flex no-gutters">
                  <div class="col">
                    {{folder.projects.length}}
                    {{folder.projects.length > 1 ? 'projects' : 'project'}}
                  </div>
                  <div class="col sp-text-secondary text-right align-self-center">
                    Created: {{ dateToString(folder.created_at) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sp-card sp-card-2">
            <div class="card">
              <div class="card-header">
                <div class="row pt-3 d-flex no-gutters">
                  <div class="col">
                    <h5 #folderTitle (blur)="onFolderTitleUpdate(folderTitle.innerText, folder)" class="sp-folder-name" contenteditable>{{ folder.name }}</h5>
                  </div>
                  <div class="col text-right">
                    <mat-icon [matMenuTriggerFor]="menuFolder" class="sp-project-menu">more_vert</mat-icon>

                  </div>
                </div>
                <div class="row d-flex no-gutters">
                  <div class="col sp-card-project-btn text-right">
                    <button class="btn btn-primary ml-2 rounded-circle sp-btn sp-btn-primary sp-btn-circle" matTooltip="Open" matTooltipHideDelay="10" type="button">
                    <span class="sp-icon">
                      <i (click)="selectFolder(folder)" class="icon-sp-folder-2"></i>
                    </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="ml-4 mr-4 card-body">
                <div class="sp-card-folder">
                  <div class="row d-flex no-gutters ">
                    <div class="col pb-1">
                      <h5 *ngFor="let project of folder.projects" class="pl-3 sp-benchmark">{{ project.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row pb-2 d-flex no-gutters">
                  <div class="col">
                    {{ folder.projects ? (folder.projects.length + " " + (folder.projects.length > 1) ? 'projects' : 'project') : 'Loading projects' }}
                  </div>
                  <div class="col sp-text-secondary text-right align-self-center">
                    Created: {{ dateToString(folder.created_at) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sp-card sp-card-3">
            <div class="card">
              <div class="card-header">
                <div class="row pt-3 d-flex no-gutters">
                  <div class="col">
                    <h5 #folderTitle (blur)="onFolderTitleUpdate(folderTitle.innerText, folder)" contenteditable>{{ folder.name }}</h5>
                  </div>
                  <div class="col text-right">
                    <mat-icon [matMenuTriggerFor]="menuFolder" class="sp-project-menu">more_vert</mat-icon>

                  </div>
                </div>
                <div class="row d-flex no-gutters">
                  <div class="col sp-card-project-btn text-right">
                    <button class="btn btn-primary ml-2 rounded-circle sp-btn sp-btn-primary sp-btn-circle" matTooltip="Open" matTooltipHideDelay="10" type="button">
                    <span class="sp-icon">
                      <i (click)="selectFolder(folder)" class="icon-sp-folder-2"></i>
                    </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="ml-4 mr-4 card-body">
                <div class="sp-card-folder">
                  <div class="row d-flex no-gutters ">
                    <div class="col pb-1">
                      <h5 *ngFor="let project of folder.projects" class="pl-3 sp-benchmark">{{ project.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row pb-2 d-flex no-gutters">
                  <div class="col">
                    {{ folder.projects ? (folder.projects.length + " " + (folder.projects.length > 1) ? 'projects' : 'project') : 'Loading projects' }}
                  </div>
                  <div class="col sp-text-secondary text-right align-self-center">
                    Created: {{ dateToString(folder.created_at) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </ng-container>
      <div *ngFor="let project of projects;let index = index" class="sp-project-items" id="allProjects">
        <div class="">
          <div class="card h-100 sp-card" id="project-{{index}}">
            <div class="card-header">
              <div *ngIf="env.config.enableDebugFeatures" class="row pt-1 d-flex no-gutters">
                <div class="col-8" style="font-size: 0.7em">PID[{{ project.id }}]|ATID[{{ project.audience_target.id }}]|ABID[{{ project.audience_bench.id }}]|CTID[{{ project.audience_target.latest_crawl ? project.audience_target.latest_crawl.id : 'N/A' }}]|CBID[{{ project.audience_bench.latest_crawl ? project.audience_bench.latest_crawl.id : 'N/A' }}]|MV[{{ project.audience_target.map_version }}]</div>
              </div>
              <div class="row pt-3 d-flex no-gutters">
                <div class="col-8" >
                  <input #title (blur)="onProjectTitleUpdate(title, project); isInsideName = false" (keyup.enter)="title.blur()" [hidden]="!isTitleEditable(title)" [value]="project.name" class="sp-project-name" id="project-input-{{project.id}}" matTooltip="{{project.name}}" matTooltipHideDelay="10" >
                  <div #subTitle (click)="editTitle(title)" [hidden]="isTitleEditable(title)" class="sp-project-name" matTooltip="{{project.name}}" matTooltipHideDelay="10">{{ project.name }}</div>
                </div>
                <div class="col text-right">
                  <mat-icon [matMenuTriggerFor]="menuProject" class="sp-project-menu">more_vert</mat-icon>
                  <mat-menu #menuProject="matMenu" class="sp-dropdown sp-menu-preset">
                    <div class="row">
                      <button (click)="openProjectDetailsDialog(project)" mat-menu-item><i class="icon-sp-info"></i><span class="pl-4">Overview</span></button>
                    </div>
                    <div [matTooltip]="freemium ? 'Not available for free users.' : !project._owner ? 'Only the project owner can share this project. Please duplicate it to be free to perform this action.' : '' " class="row">
                      <button (click)="openShareDialog(project)" [disabled]="!criterionAvailable(project) || freemium || !project._owner" mat-menu-item><i class="icon-sp-share"></i><span class="pl-4">Share</span></button>
                    </div>
                    <div *ngIf="canExportToMeta" [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button [disabled]="!criterionAvailable(project) || freemium" mat-menu-item (click)="goToMetaExport(project)">
                        <span class="d-flex">
                            <span class="material-icons">
                          upgrade
                        </span>
                        <span class="pl-4">Export to Meta</span>
                        </span>
                      </button>
                    </div>
                    <div [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button (click)="openAddToFolderDialog(project)" [disabled]="freemium" mat-menu-item><i class="icon-sp-folder-add"></i><span class="pl-4">{{ projectManager.openedFolder ? 'Move' : 'Add' }} to folder</span></button>
                    </div>
                    <div *ngIf="projectManager.openedFolder" [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button (click)="removeProjectFromFolder(project)" [disabled]="freemium" mat-menu-item><i class="icon-sp-folder-empty"></i><span class="pl-4">Remove from folder</span></button>
                    </div>
                    <div  [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button (click)="subTitle.click()" [disabled]="freemium" mat-menu-item><i class="icon-sp-edit-black"></i><span class="pl-4">Rename</span></button>
                    </div>
                    <div  [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button (click)="openDeleteProjectDialog(project)" [disabled]="!env.config.enableDebugFeatures && (isProjectCrawling(project) || freemium)"  mat-menu-item><i class="icon-sp-delete"></i><span class="pl-4">Delete</span></button>
                    </div>
                    <div [matTooltip]="freemium ? 'Not available for free users.' : !project._owner ? 'Only the project owner can refresh this project. Please duplicate it to be free to perform this action.' : '' " class="row"  matTooltipHideDelay="10">
                      <button (click)="openRefreshProjectCreditsDialog(project)" [disabled]="(isProjectCrawling(project) || !project._owner || freemium) && !env.config.enableDebugFeatures" mat-menu-item><i class="icon-sp-back"></i><span class="pl-4">Refresh Project</span></button>
                    </div>
                    <div  [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button (click)="openDuplicateProjectCreditsDialog(project)" [disabled]="isProjectCrawling(project) || freemium" mat-menu-item><i class="icon-sp-contract"></i><span class="pl-4">Duplicate Project</span></button>
                    </div>
                    <div *ngIf="canAccessDashboard" [matTooltip]="freemium ? 'Not available for free users.' : ''" class="row">
                      <button [disabled]="freemium || isProjectCrawling(project)" mat-menu-item routerLink="{{'/dashboard/reporting/list/' + project.id}}"><i class="icon-sp-linear-chart"></i><span class="pl-4">Go to reporting</span></button>
                    </div>
                    <div *ngIf="env.config.enableDebugFeatures" class="row">
                      <button (click)="setProjectMapVersion(project, 1)" mat-menu-item><span class="pl-4">[DBUG] Set map V1</span></button>
                    </div>
                    <div *ngIf="env.config.enableDebugFeatures" class="row">
                      <button (click)="setProjectMapVersion(project, 2)" mat-menu-item><span class="pl-4">[DBUG] Set map V2</span></button>
                    </div>
                    <div *ngIf="env.config.enableDebugFeatures" class="row">
                      <button (click)="clearProjectCache(project)" mat-menu-item><span class="pl-4">[DBUG] Clear cache</span></button>
                    </div>
                    <div *ngIf="env.config.enableDebugFeatures" class="row">
                      <button (click)="projectCrawlCancel(project)" mat-menu-item><span class="pl-4">[DBUG] Cancel crawl</span></button>
                    </div>
                    <div *ngIf="env.config.enableDebugFeatures" class="row">
                      <button (click)="debugSendProjectCreate(project)" mat-menu-item><span class="pl-4">[DBUG] Send create notification</span></button>
                    </div>
                  </mat-menu>
                </div>
              </div>


              <div class="row d-flex no-gutters">
                <div class="col align-self-end" >
                  <div *ngIf="!isProjectCrawling(project)" matTooltip="Personae generated : {{project.audience_target.audiences_attached.length}}" matTooltipHideDelay="10">
                    <span *ngFor="let i of project.audience_target.audiences_attached.slice(0,3)">
                      <i class="icon-sp-user-solo"></i>
                    </span>
                  </div>
                </div>
                <div class="col-4 sp-card-project-btn text-right" id="project-button-{{index}}">
                  <button (click)="router.navigate([navigation.linkInsights(project.id)])" *ngIf="criterionAvailable(project)" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle" matTooltip="View Insights" matTooltipHideDelay="10" type="button" >
                    <span class="sp-icon">
                      <i class="icon-sp-chart"></i>
                    </span>
                  </button>
                  <button (click)="router.navigate([navigation.linkPersonaOverview(project.id)])" *ngIf="criterionAvailable(project)" class="btn btn-primary ml-2 rounded-circle sp-btn sp-btn-primary sp-btn-circle" matTooltip="View Personae" matTooltipHideDelay="10" type="button">
                    <span class="sp-icon">
                      <i class="icon-sp-users"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="ml-4 mr-4 card-body">
              <div class="row d-flex no-gutters">
                <div class="col">
                  <h5 *ngIf="project.audience_target.name.length > 25" class="pl-3 sp-audience" matTooltip="{{project.audience_target.name}}" matTooltipHideDelay="10">{{ project.audience_target.name.substr(0,24) }}...</h5>
                  <h5 *ngIf="project.audience_target.name.length <= 25" class="pl-3 sp-audience" matTooltip="{{project.audience_target.name}}" matTooltipHideDelay="10">{{ project.audience_target.name }}</h5>
                  <h6 class="pl-4 sp-text-secondary">{{ project.audience_target.latest_crawl ? toNumberFormat(project.audience_target.latest_crawl.audience_size) + " people" : "Size not available yet" }}</h6>
                </div>
              </div>
              <div class="row d-flex no-gutters">
                <div class="col">
                  <h5 *ngIf="project.audience_bench.name.length > 25" class="pl-3 sp-benchmark" matTooltip="{{project.audience_bench.name}}" matTooltipHideDelay="10">{{ project.audience_bench.name.substr(0,24) }}...</h5>
                  <h5 *ngIf="project.audience_bench.name.length <= 25" class="pl-3 sp-benchmark" matTooltip="{{project.audience_bench.name}}" matTooltipHideDelay="10">{{ project.audience_bench.name}}</h5>
                  <h6 class="pl-4 sp-text-secondary">{{ project.audience_bench.latest_crawl ? toNumberFormat(project.audience_bench.latest_crawl.audience_size) + " people" : "Size not available yet" }}</h6>
                </div>
              </div>
            </div>
            <!-- Progression and users info -->
            <div class="card-footer">
              <div class="row d-flex no-gutters">
                <div *ngIf="isProjectCrawling(project)" class="col-2">
                  <div class="sp-progress-bar-nb">
                    <span>{{ crawlTracker.progress(project) }}%</span>
                  </div>
                </div>
                <div *ngIf="isProjectCrawling(project)" class="col-10" >
                  <span style="position: absolute;bottom: 1.5rem">{{ projectCrawlStatus(project) ? projectCrawlStatus(project).loadingStatus : "Loading..." }}</span>
                  <mat-progress-bar [value]="crawlTracker.progress(project)" class="sp-progress-bar" mode="determinate"></mat-progress-bar>
                </div>
                <div *ngIf="!isProjectCrawling(project)" class="col">
                  <span *ngFor="let user of getProjectUsers(project).slice(0, 3); let index = index">
                    <img #tooltip="matTooltip" (click)="tooltip.toggle()" *ngIf="user.image !== null" [src]="user.image !== null ? env.config.api.storageUrl + user.image : env.config.noPpImage" class="sp-avatar sp-avatar-md sp-card-avatar sp-avatar-noClick" matTooltip="{{user.first_name}} {{user.last_name}}{{ user.id == project.user.id ? ' (Owner)' : '' }}" matTooltipHideDelay="10">
                    <span #tooltip="matTooltip" (click)="tooltip.toggle()" *ngIf="user.image == null" class="sp-avatar sp-avatar-md avatar-rounded sp-card-avatar sp-avatar-noClick text-center" matTooltip="{{user.first_name}} {{user.last_name}} {{ user.id == project.user.id ? ' (Owner)' : '' }}" matTooltipHideDelay="10">
                      <span class="avatar-user-name ">{{userInitials(user)}}</span>
                    </span>
                  </span>
                  <span *ngIf="getProjectUsers(project).length > 3">
                        <span #tooltip="matTooltip" (click)="tooltip.toggle()" class="sp-avatar sp-avatar-md avatar-rounded sp-card-avatar text-center" matTooltip="{{allUsersInProject}}" matTooltipHideDelay="10">
                        <span class="avatar-user-name ">...</span>
                      </span>
                   </span>
                </div>
                <div *ngIf="!isProjectCrawling(project)" class="col sp-text-secondary text-right align-self-center">
                  Created: {{ dateToString(project.created_at) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="hideScrollCard" class="card sp-card">
        <div *ngIf="!chunkLoading" class="card-body mt-2">
          <div class="text-center">
            <button (click)="buttonLoadProject()" class="btn btn-primary rounded-circle sp-btn sp-btn-circle sp-btn-remove mb-3" type="button">
              <mat-icon class="sp-input-search-icon" matPrefix svgIcon="icon_sp_scroll"></mat-icon>
            </button>
            <p class="text-uppercase">Scroll to load more project</p>
          </div>
          <div class="w-100 text-center">
            <img class="sp-load-img" src="assets/images/Load.png">
          </div>
        </div>
        <div *ngIf="chunkLoading" class="card-body">
          <div  class="sp-spinner basic-container">
            <mat-spinner strokeWidth="3"></mat-spinner>
            <img src="../assets/images/logos/soprism/soprism-logo-sign.png">
          </div>
        </div>
      </div>
      <div class="filling-empty-space-childs "></div>
      <div class="filling-empty-space-childs "></div>
      <div class="filling-empty-space-childs "></div>
      <div class="filling-empty-space-childs "></div>
      <div class="filling-empty-space-childs "></div>
  </div>
</div>
