<div class="card" style="padding: 24px 36px 32px;gap: 32px">
  <div class="d-flex flex-column align-items-center" style="gap: 16px">
    <h5>This action will consume credits</h5>
    <div>
      You will be debited with {{ data.creditCount }} credit. After that, there will be {{ creditsLeft }} credits {{ showCreditExtra ? ('and ' + creditsExtraLeft + ' extra credits') : '' }} left.
    </div>
  </div>
  <div class="d-flex flex-column align-items-center" style="gap:16px">
    <div>Are you sure you want to continue?</div>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" (click)="cancel()" routerLinkActive="active" matTooltip="Cancel this operation" matTooltipHideDelay="10">
        <span>Cancel</span>
      </button>
      <button (click)="confirm()" class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Confirm this operation" matTooltipHideDelay="10">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>
