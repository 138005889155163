import {Injectable} from '@angular/core';
import {ApiDataService} from '../sp-api/sp-api-data/api-data.service';
import {Project} from '../../models/project';
import {CrawlStatus} from '../../models/crawl-status';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Audience} from '../../models/audience';
import {LoggerService} from '../sp-logger/logger.service';
import {AlertService} from '../sp-alert/alert.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CrawlTrackerService {
  public static readonly STEP_CRITERIA_AVAILABLE = 2;
  public static readonly STEP_PERSONAE = 3;

  private waitingRoom: Project[] = [];
  private personaeWaitingRoom: Audience[] = [];
  private intervals: Map<number, any> = new Map();
  private personaIntervals: Map<number, any> = new Map();

  crawlDoneNotifier: Subject<Project> = new Subject<Project>();
  crawlCriteriaDoneNotifier: Subject<Project> = new Subject<Project>();
  projectCleanNotifier: Subject<Project> = new Subject<Project>();
  personaCrawlDoneNotifier: Subject<Audience> = new Subject<Audience>();

  project;
  constructor(
    private apiData: ApiDataService,
    private logger: LoggerService,
    private alert: AlertService,
    private router: Router,
  ) { }

  clearProject(project: Project) {
    this.waitingRoom = this.waitingRoom.filter(p => p.id != project.id);
    const interval = this.intervals.get(project.id);
    if (interval) clearInterval(interval);
  }

  clearAll() {
    this.waitingRoom = [];
    this.personaeWaitingRoom = [];
    Array.from(this.intervals.values()).forEach(interval => {
      clearInterval(interval);
    });
    Array.from(this.personaIntervals.values()).forEach(interval => {
      clearInterval(interval);
    });
    this.intervals = new Map();
    this.personaIntervals = new Map();
  }

  addPersona(audience: Audience, watchInterval: number = 10000) {
    if (!this.inPersonaeWaitingRoom(audience)) {
      this.logger.logInfo("[TRACKER] Adding " + audience.name + " to persona tracking waiting room");
      let target: Audience = audience;
      let bench: Audience = audience.benchmark;

      let targetCrawling: boolean = true;
      let benchCrawling: boolean = true;

      if (!bench) {
        this.logger.logError("[TRACKER] Adding persona " + audience.name + " with no defined benchmark, are you sure it's a persona ?");
      } else {
        this.apiData.getAudienceStatus(target).then(status => {
          target.status = status;
        }).catch(error => {
          if (error.status == 404) {
            target.status = CrawlStatus.done();
            if (target.latest_crawl) {
              target.latest_crawl.criteria_ready = true;
              target.latest_crawl.segment_ready = true
            }
            targetCrawling = false;
          } else if (error.status == 500) {
            target.status = CrawlStatus.unavailable();
            targetCrawling = false;
          }
        }).finally(() => {
          this.apiData.getAudienceStatus(bench).then(status => {
            bench.status = status;
          }).catch(error => {
            if (error.status == 404) {
              bench.status = CrawlStatus.done();
              if (bench.latest_crawl) {
                bench.latest_crawl.criteria_ready = true;
                bench.latest_crawl.segment_ready = true
              }
              benchCrawling = false;
            } else if (error.status == 500) {
              bench.status = CrawlStatus.unavailable();
              benchCrawling = false;
            }
          }).finally(() => {
            if (targetCrawling || benchCrawling) {
              this.personaeWaitingRoom.push(target);
              this.startWatchingPersona(target, watchInterval);
            }
          })
        })
      }
    }

    return audience;
  }

  async addProject(project: Project, watchInterval: number = 10000) {
    if (project.id == 0) {
      project.audience_target.status = CrawlStatus.done();
      project.audience_bench.status = CrawlStatus.done();
      return;
    }

    if (!this.inWaitingRoom(project)) {
      const target = project.audience_target;
      const bench = project.audience_bench;

      const targetCrawling = await this.getAndAssignAudienceCrawlStatus(target);
      const benchCrawling = await this.getAndAssignAudienceCrawlStatus(bench);

      if (!this.inWaitingRoom(project)) {
        if (targetCrawling || benchCrawling) {
          this.logger.logInfo("[TRACKER] Adding " + project.name + " (ID: " + project.id + ") to project tracking waiting room");
          this.waitingRoom.push(project);
          this.startWatching(project, watchInterval);
        } else {
          project._ready = true;
          this.projectCleanNotifier.next(project);
        }
      }
    }
  }

  private async getAndAssignAudienceCrawlStatus(target: Audience) {
    try {
      target.status = await this.apiData.getAudienceStatus(target);
      return target.status.state !== 'done';
    } catch (error) {
      if (error.status == 404) {
        target.status = CrawlStatus.done();
        if (target.latest_crawl) {
          target.latest_crawl.criteria_ready = true;
          target.latest_crawl.segment_ready = true;
        }
      } else if (error.status == 500) {
        target.status = CrawlStatus.unavailable();
      }

      return false;
    }
  }

  private startWatching(project: Project, interval: number) {
    const id = setInterval(() => {
      const target = project.audience_target;
      const bench = project.audience_bench;

      this.apiData.getAudienceStatus(target).then(status => {
        target.status = status;
      }).catch(error => {
        if (error.status == 404) {
          target.status = CrawlStatus.done();
          if (target.latest_crawl) {
            target.latest_crawl.criteria_ready = true;
            target.latest_crawl.segment_ready = true;
          }
        } else {
          clearInterval(this.intervals.get(project.id))
          if (environment.config.crawlErrorBypass) {
            this.logger.logWarning("[TRACKER] Target crawl fetch resulting error but ignored (crawl error bypass)");
            target.status = CrawlStatus.done();
            if (target.latest_crawl) {
              target.latest_crawl.criteria_ready = true;
              target.latest_crawl.segment_ready = true;
            }
          }
        }
      }).finally(() => {
        this.apiData.getAudienceStatus(bench).then(status => {
          bench.status = status;
        }).catch(error => {
          if (error.status == 404) {
            bench.status = CrawlStatus.done();
            if (bench.latest_crawl) {
              bench.latest_crawl.criteria_ready = true;
              bench.latest_crawl.segment_ready = true;
            }
          } else {
            clearInterval(this.intervals.get(project.id));
            if (environment.config.crawlErrorBypass) {
              this.logger.logWarning("[TRACKER] Benchmark crawl fetch resulting error but ignored (crawl error bypass)");
              bench.status = CrawlStatus.done();
              if (bench.latest_crawl) {
                bench.latest_crawl.criteria_ready = true;
                bench.latest_crawl.segment_ready = true;
              }
            }
          }
        }).finally(() => {
          if (target.status && bench.status) {
            if (this.waitingRoom.length == 1 && environment.config.enableExperimentalFeatures) {
              document.title = 'Dashboard | ' + this.progress(project) + '% ' + [target.status.actualStep, bench.status.actualStep].reduce((a, b) => a < b ? a : b) + '/' + target.status.steps;
            }
            if (target.status.state == "done" && bench.status.state == "done") {
              if (environment.config.enableExperimentalFeatures) document.title = 'SoPRISM - Dashboard';
              clearInterval(this.intervals.get(project.id));
              this.waitingRoom = this.waitingRoom.filter(p => p.id !== project.id);
              if (target.latest_crawl) {
                target.latest_crawl.criteria_ready = true;
                target.latest_crawl.segment_ready = true;
              }
              if (bench.latest_crawl) {
                bench.latest_crawl.criteria_ready = true;
                bench.latest_crawl.segment_ready = true;
              }
              this.crawlDoneNotifier.next(project);
              this.setUpToasterNotification(project);

            } else if (!project._ready && target.status.actualStep > CrawlTrackerService.STEP_CRITERIA_AVAILABLE && bench.status.actualStep > CrawlTrackerService.STEP_CRITERIA_AVAILABLE) {
              if (target.latest_crawl) target.latest_crawl.criteria_ready = true;
              if (bench.latest_crawl) bench.latest_crawl.criteria_ready = true;
              this.crawlCriteriaDoneNotifier.next(project);
              project._ready = true;
            }
          }
        })
      });
    }, interval);

    this.intervals.set(project.id, id);
  }

  private setUpToasterNotification(project: Project) {
    const toast = this.alert.notify('Success', 'Your project: ' + project.name + ' has finished crawling, click here to see it', 'success', {timeOut: 10000});

    if (toast.onTap) {
      toast.onTap.subscribe(() => {
        this.router.navigate(['/projects/details/' + project.id + '/insights/socio-demo']).then();
      });
    }
  }

  private startWatchingPersona(persona: Audience, interval: number) {
    const id = setInterval(() => {
      const target = persona;
      const bench = persona.benchmark;

      this.apiData.getAudienceStatus(target).then(status => {
        target.status = status;
      }).catch(error => {
        if (error.status == 404) {
          target.status = CrawlStatus.done();
        } else {
          clearInterval(this.personaIntervals.get(persona.id));
          if (environment.config.crawlErrorBypass) {
            this.logger.logWarning("[TRACKER] Persona target crawl fetch resulting error but ignored (crawl error bypass)");
            target.status = CrawlStatus.done();
          }
        }
      }).finally(() => {
        this.apiData.getAudienceStatus(bench).then(status => {
          bench.status = status;
        }).catch(error => {
          if (error.status == 404) {
            bench.status = CrawlStatus.done();
          } else {
            clearInterval(this.personaIntervals.get(persona.id));
            if (environment.config.crawlErrorBypass) {
              this.logger.logWarning("[TRACKER] Persona benchmark crawl fetch resulting error but ignored (crawl error bypass)");
              bench.status = CrawlStatus.done();
            }
          }
        }).finally(() => {
          if (target.status && bench.status) {
            if (target.status.state == "done" && bench.status.state == "done") {
              clearInterval(this.personaIntervals.get(persona.id))
              this.personaeWaitingRoom = this.personaeWaitingRoom.filter(p => p.id !== target.id);
              this.personaCrawlDoneNotifier.next(persona);
            }
          }
        })
      });
    }, interval);

    this.personaIntervals.set(persona.id, id);
  }

  inWaitingRoom(project: Project): boolean {
    return !!(this.waitingRoom.find(w => w.id == project.id));
  }

  inPersonaeWaitingRoom(audience: Audience): boolean {
    return !!(this.personaeWaitingRoom.find(a => a.id == audience.id));
  }

  personaProgress(persona: Audience): number {
    const personato = this.personaeWaitingRoom.find(p => p.id == persona.id);
    if (!personato) {
      this.logger.debug("Cannot find persona");
      return 0;
    }

    const target = personato;
    const bench = personato.benchmark;

    const targetStatus = target.status;
    const benchStatus = bench.status;

    const targetPercent = CrawlTrackerService.getPercentage(targetStatus);
    const benchPercent = CrawlTrackerService.getPercentage(benchStatus);

    const average = (targetPercent + benchPercent) / 2;
    return Math.round(average * 100);
  }

  progress(project: Project): number {
    const projecto = this.waitingRoom.find(p => p.id == project.id);
    if (!projecto) return 0;

    const target = projecto.audience_target;
    const bench = projecto.audience_bench;

    const targetStatus = target.status;
    const benchStatus = bench.status;

    let targetPercent;
    let benchPercent;

    if (targetStatus.actualStep == CrawlTrackerService.STEP_PERSONAE && benchStatus.actualStep < CrawlTrackerService.STEP_PERSONAE) {
      targetPercent = 1;
    } else {
      targetPercent = CrawlTrackerService.getPercentage(targetStatus);
    }

    if (benchStatus.actualStep == CrawlTrackerService.STEP_PERSONAE && targetStatus.actualStep < CrawlTrackerService.STEP_PERSONAE) {
      benchPercent = 1;
    } else {
      benchPercent = CrawlTrackerService.getPercentage(benchStatus);
    }

    let average = (targetPercent + benchPercent) / 2;
    average = Math.round(average * 100);

    if (average == 100 && (targetStatus.progress != targetStatus.size || benchStatus.progress != benchStatus.size)) {
      average--;
    }

    return average;
  }

  private static getPercentage(status: CrawlStatus): number {
    let percentage = 0;

    if (!status) return 0;
    else if (status.state == "done") return 1;
    else if (status.size == 0) return 0;
    else if (status.actualStep < this.STEP_PERSONAE) {
      const adjustedSteps = status.steps - 1; // Adjusted steps to not include persona crawl
      percentage = ((status.actualStep / adjustedSteps) - 1 / adjustedSteps) + (status.progress / status.size) * (1 / adjustedSteps);
    } else if (status.actualStep == this.STEP_PERSONAE) {
      percentage = status.progress / status.size;
    }

    return percentage;
  }
}
