<div (click)="closeNav()" class="overlay" id="overlay"></div>
<div class="sidenav bg-color-white-900" id="mySidenav">
  <div class="h-100 flex flex-col justify-between gap-32 pt-32 pl-40 pr-40 pb-40" >
    <div class="flex flex-col gap-16">
      <div class="sp-drawer-header">
        Filters
      </div>
      <div class="sp-drawer-body">
        <div *ngIf="router.url.includes('persona/manager')" class="flex flex-col gap-24">
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Segmentation Options</span>
            <div class="flex gap-12">
              <button (click)="mindsetsActive = false; " [ngClass]="mindsetsActive == false ? 'sp-btn-active' : 'sp-btn-inactive'">
                <span>All results</span>
              </button>
              <button (click)="mindsetsActive = true; genderFilter = 4;" [ngClass]="mindsetsActive == true ? 'sp-btn-active' : 'sp-btn-inactive'">
                <span>Show only mindsets</span>
              </button>
            </div>
          </div>
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Gender</span>
            <div class="flex gap-12">
              <button (click)="updateGender(4)" [ngClass]="genderFilter == 4 ? 'sp-btn-active' : 'sp-btn-inactive'">
                <span>All results</span>
              </button>
              <button #btnMan (click)="updateGender(1); btnMan.blur()" [disabled]="mindsetsActive " [ngClass]="genderFilter == 1 || genderFilter == 3 ? 'sp-btn-active' : 'sp-btn-inactive'" [style.opacity]="mindsetsActive ? '50%' : '100%'">
                <span>Men</span>
              </button>
              <button #btnWoman (click)="updateGender(2); btnWoman.blur()" [disabled]="mindsetsActive " [ngClass]="genderFilter == 2 || genderFilter == 3 ? 'sp-btn-active' : 'sp-btn-inactive'" [style.opacity]="mindsetsActive ? '50%' : '100%'">
                <span>Women</span>
              </button>
            </div>
          </div>
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Age</span>
            <div class="flex gap-12 items-center">
              <div class="flex gap-8 items-center">
                <span class="sp-drawer-text">Min.</span>
                <select [disabled]="mindsetsActive" (ngModelChange)="updateAgeOptions()" [(ngModel)]="minAge" class="sp-drawer-select">
                  <option *ngFor="let age of availableAgesMin" [disabled]="isMinAgeDisabled(age)" [value]="age">
                    {{ age }}
                  </option>
                </select>
              </div>
              <span>-</span>
              <div class="flex gap-8 items-center">
                <span>Max.</span>
                <select [disabled]="mindsetsActive" (ngModelChange)="updateAgeOptions()" [(ngModel)]="maxAge" class="sp-drawer-select">
                  <option *ngFor="let age of availableAgesMax" [disabled]="isMaxAgeDisabled(age)" [value]="age">
                    {{ age }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Segment Size</span>
            <div class="flex gap-12 items-center">
              <div class="flex gap-8 items-center">
                <span class="sp-drawer-text">Min.</span>
                <input (input)="validateInputs()"  [(ngModel)]="targetSizeMin" [ngClass]="{ 'error': isMinGreaterThanMax }"  class="sp-input-range" id="minSize" maxlength="10" spThousandSeparator style="width: 120px;" type="text" />
              </div>
              <span>-</span>
              <div class="flex gap-8 items-center">
                <span class="sp-drawer-text">Max.</span>
                <input (input)="validateInputs()"  [(ngModel)]="targetSizeMax" [ngClass]="{ 'error': isMinGreaterThanMax }"  class="sp-input-range"  id="maxSize" maxlength="10" spThousandSeparator style="width: 120px" type="text"/>
              </div>
              <span *ngIf="isMinGreaterThanMax"  style="color:  #C62F38;font-family: 'Roboto Condensed'; font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;">Not valid range</span>
            </div>
          </div>
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Segments</span>
            <mat-form-field appearance="outline" class="w-100 sp-form-field">
              <mat-label class="sp-input-label">Segment</mat-label>
              <mat-select [(ngModel)]="segmentSelected" class="sp-input-content" panelClass="sp-select-option-icon">
                <input [(ngModel)]="searchSegment" [ngModelOptions]="{standalone: true}" aria-label="Number" class="sp-input-search-content" matInput placeholder="Pick one" type="text">
                <mat-option value=""><b>No filter</b></mat-option>
                <mat-option *ngFor="let segment of filterService.segments |  FilterPipe : searchSegment" [title]="segment" [value]="segment">{{ segment }}</mat-option>
              </mat-select>
              <span class="material-symbols-outlined"  matSuffix matTooltip="This menu allows you to access to your facebook’s audiences and select it to analyze it in SOPRISM." matTooltipClass="sp-tooltip-launch-segmentation" matTooltipHideDelay="10" style="width: 20px;height: 20px;margin-right: 10px;color: #EF8251;cursor: help">
            info
          </span>
            </mat-form-field>
          </div>
          <div class="flex flex-col gap-16">
            <span class="sp-drawer-header-add-subtitle">Categories</span>
            <div class="sp-chips-category-parent">
              <mat-form-field appearance="outline" class="w-100 sp-form-field">
                <mat-label class="sp-input-label">Categories</mat-label>
                <input #mindsetCategorySearchInput [(ngModel)]="searchMindsetCategory" [matAutocomplete]="auto"
                       matInput
                       placeholder="Pick one"
                       type="text">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onMindsetCategorySelect($event.option.value)">
                  <mat-option *ngFor="let option of mindsetCategories.sort(sortbyName) | FilterPipe: searchMindsetCategory" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div *ngIf="selectedMindsetCategories?.length > 0" class="sp-chips-category-container">
                <mat-chip (removed)="removeMindsetCategory(mindsetCategory)" *ngFor="let mindsetCategory of selectedMindsetCategories" [removable]="true" class="sp-chips-category">
                  {{ mindsetCategory.name }}
                  <svg fill="none" height="16" matChipRemove viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" d="M2.68306 2.18306C2.92714 1.93898 3.32286 1.93898 3.56694 2.18306L8.5 7.11612L13.4331 2.18306C13.6771 1.93898 14.0729 1.93898 14.3169 2.18306C14.561 2.42714 14.561 2.82286 14.3169 3.06694L9.38388 8L14.3169 12.9331C14.561 13.1771 14.561 13.5729 14.3169 13.8169C14.0729 14.061 13.6771 14.061 13.4331 13.8169L8.5 8.88388L3.56694 13.8169C3.32286 14.061 2.92714 14.061 2.68306 13.8169C2.43898 13.5729 2.43898 13.1771 2.68306 12.9331L7.61612 8L2.68306 3.06694C2.43898 2.82286 2.43898 2.42714 2.68306 2.18306Z" fill="#EF8251" fill-rule="evenodd"/>
                  </svg>
                </mat-chip>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="router.url.includes('persona/overview') || router.url.includes('insights')" class="flex flex-col gap-24">
            <div *ngIf="navigation.tagFilterEnabled">
                <h3 class="text-uppercase"><strong>Filter on Thematic universes</strong></h3>
                <h4 class="font-normal">Select Target Penetration</h4>
                <div>
                    <h6>Range: {{rangeValuesPenetrationTags[0] + ' - ' + rangeValuesPenetrationTags[1]}} %</h6>
                    <p-slider [(ngModel)]="rangeValuesPenetrationTags" [range]="true" class="m-2"></p-slider>
                </div>
                <h4 class=" font-normal">Select Selectivity</h4>
                <div>
                    <h6>Range: {{rangeValuesSelectivityTags[0] + ' - ' + rangeValuesSelectivityTags[1]}} </h6>
                    <p-slider [(ngModel)]="rangeValuesSelectivityTags" [max]="tagScorableMaxThreshold" [min]="0" [range]="true" class="m-2"></p-slider>
                </div>
                <ng-container *ngIf="tagStat?.length > 0">
                    <h4 class=" font-normal pb-2">Select thematics universes to remove</h4>
                    <mat-form-field appearance="outline" class="w-100 sp-form-field pb-2">
                        <mat-label class="sp-input-label">Thematics Universes</mat-label>
                        <input class="sp-input-content" [matAutocomplete]="tagAuto" (blur)="closeMatPanel(tagTrigger)" #tagInput #tagTrigger="matAutocompleteTrigger" matInput placeholder="Type your tag here..."/>
                        <mat-autocomplete (optionSelected)="onTagBlacklistSelect($event.option.value); tagInput.value = ''; openMatPanel(tagTrigger)" #tagAuto="matAutocomplete" [displayWith]="statToString">
                            <mat-option *ngFor="let tag of tagStat | FilterPipe:tagInput.value" [value]="tag" [disabled]="!tag">
                                {{ tag.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-chip-listbox aria-label="Tag selection" class="sp-chips sp-chips-filter" multiple >
                            <span *ngFor="let tag of tagBlacklist" class="pl-2 pr-2">
                               <mat-chip-option>
                                 <span>{{tag.name}}</span>
                                  <mat-icon (click)="onTagBlacklistRemove(tag)" class="sp-icon-chips-delete"
                                            matChipRemove style="transform: translateY(-1px)" svgIcon="icon_sp_x">cancel</mat-icon>

                               </mat-chip-option>
                            </span>
                    </mat-chip-listbox>
                </ng-container>
            </div>
            <div *ngIf="navigation.criteriaFilterEnabled" class="pt-3">
                <h3 class="text-uppercase"><strong>Filter on criteria</strong></h3>
                <h4 class="font-normal">Select Target Penetration</h4>
                <div>
                    <h6>Range: {{rangeValuesPenetrationCriteria[0] + ' - ' + rangeValuesPenetrationCriteria[1]}} %</h6>
                    <p-slider [(ngModel)]="rangeValuesPenetrationCriteria" [range]="true" class="m-2"></p-slider>
                </div>
                <h4 class=" font-normal">Select Selectivity</h4>
                <div>
                    <h6>Range: {{rangeValuesSelectivityCriteria[0] + ' - ' + rangeValuesSelectivityCriteria[1]}} </h6>
                    <p-slider [(ngModel)]="rangeValuesSelectivityCriteria" [max]="criteriaScorableMaxThreshold" [min]="0" [range]="true" class="m-2"></p-slider>
                </div>
                <ng-container *ngIf="criteriaStat?.length > 0">
                    <h4 class=" font-normal pb-2">Select interest to remove</h4>
                    <mat-form-field appearance="outline" class="w-100 sp-form-field pb-2">
                        <mat-label class="sp-input-label">Interest</mat-label>
                        <input class="sp-input-content" [matAutocomplete]="criteriaAuto" (blur)="closeMatPanel(criteriaTrigger)" #criteriaInput #criteriaTrigger="matAutocompleteTrigger" matInput placeholder="Type your interest here..."/>
                        <mat-autocomplete (optionSelected)="onCriteriaBlacklistSelect($event.option.value); criteriaInput.value = ''; openMatPanel(criteriaTrigger)" #criteriaAuto="matAutocomplete" [displayWith]="statToString">
                            <mat-option *ngFor="let criteria of criteriaStat | FilterPipe:criteriaInput.value" [value]="criteria" [disabled]="!criteria">
                                {{ criteria.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-chip-listbox aria-label="Criteria selection" class="sp-chips sp-chips-filter" multiple>
                            <span *ngFor="let criteria of criteriaBlacklist" class="pl-2 pr-2">
                               <mat-chip-option #criteriaChip >
                                 <span>{{criteria.name}}</span>
                                  <mat-icon (click)="onCriteriaBlacklistRemove(criteria)" class="sp-icon-chips-delete"
                                            matChipRemove style="transform: translateY(-1px)" svgIcon="icon_sp_x">cancel</mat-icon>

                               </mat-chip-option>
                            </span>
                    </mat-chip-listbox>
                </ng-container>
            </div>
        </div>
      </div>
    </div>
    <div class="sp-drawer-footer">
      <app-titan-button [isDisabled]="false" [onPress]="closeNav" [variant]="'secondary'">
        <ng-container children>
          <div>Cancel</div>
        </ng-container>
      </app-titan-button>
      <app-titan-button [isDisabled]="isMinGreaterThanMax"  [onPress]="applyFilters" [variant]="'primary'">
        <ng-container children>
          <div>Apply Filters</div>
        </ng-container>
      </app-titan-button>
    </div>
  </div>
</div>
