import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpLayoutModule } from './modules/sp-layout/sp-layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor} from "./interceptors/api-interceptor";
import {CookieService} from "ngx-cookie-service";
import {RouterModule} from "@angular/router";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SpModalCompanyAddUsersComponent } from './modules/sp-admin/sp-admin-company/sp-admin-company-create/sp-modal-company-add-users/sp-modal-company-add-users.component';
import { SpModalCompanyRemoveComponent } from './modules/sp-admin/sp-admin-company/sp-admin-company-list/sp-modal-company-remove/sp-modal-company-remove.component';
import { SpModalProjectCreateDetailsComponent } from './modules/sp-project/sp-project-create/sp-modal-project-create-details/sp-modal-project-create-details.component';
import { SpModalProjectListShareComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-share/sp-modal-project-list-share.component';
import { SpModalProjectListAddToFolderComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-add-to-folder/sp-modal-project-list-add-to-folder.component';
import { SpModalProjectListFolderCreateComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-folder-create/sp-modal-project-list-folder-create.component';
import { SpModalProjectListProjectDetailsComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-project-details/sp-modal-project-list-project-details.component';
import { SpModalProjectListProjectDeleteComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-project-delete/sp-modal-project-list-project-delete.component';
import { SpModalProjectListFolderDeleteComponent } from './modules/sp-project/sp-project-list/sp-modal-project-list-folder-delete/sp-modal-project-list-folder-delete.component';
import { SpModalProjectDetailsTagManagerAddComponent } from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-add/sp-modal-project-details-tag-manager-add.component';
import { SpModalProjectDetailsTagManagerInterestAddComponent } from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-interest-add/sp-modal-project-details-tag-manager-interest-add.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { SpModalProjectDetailsTagManagerSelectionDeleteComponent } from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-interest-delete/sp-modal-project-details-tag-manager-selection-delete.component';
import { SpChartTagAnalysisComponent } from './modules/sp-chart/sp-chart-tag-analysis/sp-chart-tag-analysis.component';
import { SpToolsModule} from './modules/sp-tools/sp-tools-module';
import {SpProjectModule} from './modules/sp-project/sp-project.module';
import { SpProjectDetailsModalUpdateDataComponent } from './modules/sp-project/sp-project-details/sp-project-details-insight/sp-project-details-insight-overview/sp-project-details-modal-update-data/sp-project-details-modal-update-data.component';
import {SpAssetManagerModule} from './modules/sp-logo/sp-asset-manager/sp-asset-manager.module';
import { SpModalAssetManagerDeleteComponent } from './modules/sp-logo/sp-asset-manager/sp-modal-asset-manager-delete/sp-modal-asset-manager-delete.component';
import { SpModalAssetManagerEditComponent } from './modules/sp-logo/sp-asset-manager/sp-modal-asset-manager-edit/sp-modal-asset-manager-edit.component';
import { SpIntroductionComponent } from './modules/sp-project/sp-project-list/sp-introduction/sp-introduction.component';
import {SpModalProjectDetailsTagManagerTagDeleteComponent} from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-tag-delete/sp-modal-project-details-tag-manager-tag-delete.component';
import { SpModalProjectDetailsTagManagerTagEditComponent } from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-tag-edit/sp-modal-project-details-tag-manager-tag-edit.component';
import { SpModalProjectDetailsTagManagerInterestEditComponent } from './modules/sp-project/sp-project-details/sp-project-details-tag/sp-project-details-tag-manager/sp-modal-project-details-tag-manager-interest-edit/sp-modal-project-details-tag-manager-interest-edit.component';
import {SpAdminCompanyModule} from './modules/sp-admin/sp-admin-company/sp-admin-company.module';
import {AppConfigService} from './services/app-config.service';
import {CarouselModule} from 'primeng/carousel';
import {SpAccountModule} from './modules/sp-account/sp-account.module';
import { GridsterModule } from 'angular-gridster2';
import { SpModalProjectAdvertisingComponent } from './modules/sp-project/sp-project-list/sp-modal-project-advertising/sp-modal-project-advertising.component';
import { SpModalMapEditComponent } from './sp-modal-map-edit/sp-modal-map-edit.component';
import { SpProjectReportingDeleteWidgetComponent} from './modules/sp-dashboard/sp-project-reporting/sp-project-reporting-delete-widget/sp-project-reporting-delete-widget.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { SpDashboardCreationModalComponent } from './modules/sp-dashboard/sp-dashboard-creation-modal/sp-dashboard-creation-modal.component';
import { SpDashboardDeleteModalComponent } from './modules/sp-dashboard/sp-dashboard-delete-modal/sp-dashboard-delete-modal.component';
import { SpDashboardTypeOfTabModalComponent } from './modules/sp-dashboard/sp-dashboard-type-of-tab-modal/sp-dashboard-type-of-tab-modal.component';
import { SpDashboardTabCreationModalComponent } from './modules/sp-dashboard/sp-dashboard-tab-creation-modal/sp-dashboard-tab-creation-modal.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import { SpDashboardShareLinkModalComponent } from './modules/sp-dashboard/sp-dashboard-share-link-modal/sp-dashboard-share-link-modal.component';
import { SpDashboardSavePresetModalComponent } from './modules/sp-dashboard/sp-dashboard-save-preset/sp-dashboard-save-preset-modal.component';

import { SpProjectReportingPersonaSelectionMessageComponent } from './modules/sp-dashboard/sp-project-reporting/sp-project-reporting-persona-selection-message/sp-project-reporting-persona-selection-message.component';
import { SpProjectReportingPersonaSelectionModalComponent } from './modules/sp-widget/sp-widget-persona/sp-project-reporting-persona-selection-modal/sp-project-reporting-persona-selection-modal.component';
import {DirectivesModule} from "./directives/directives.module";
import {RollbarErrorHandler, rollbarFactory, RollbarService} from './rollbar';
import { SpMetaTargetingComponent } from './modules/sp-meta-targeting/sp-meta-targeting.component';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {SliderModule} from 'primeng/slider';
import {MatStepperModule} from '@angular/material/stepper';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {SpProjectCreateModule} from './modules/sp-project/sp-project-create/sp-project-create.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

function initConfig(configService: AppConfigService) {
  return () => {
    return configService.loadAppConfig();
  }
}

@NgModule({
    declarations: [
        AppComponent,
        SpModalCompanyAddUsersComponent,
        SpModalCompanyRemoveComponent,
        SpModalProjectCreateDetailsComponent,
        SpModalProjectListShareComponent,
        SpModalProjectListAddToFolderComponent,
        SpModalProjectListFolderCreateComponent,
        SpModalProjectListProjectDetailsComponent,
        SpModalProjectListProjectDeleteComponent,
        SpModalProjectListFolderDeleteComponent,
        SpModalProjectDetailsTagManagerAddComponent,
        SpModalProjectDetailsTagManagerInterestAddComponent,
        SpModalProjectDetailsTagManagerSelectionDeleteComponent,
        SpChartTagAnalysisComponent,
        SpProjectDetailsModalUpdateDataComponent,
        SpModalAssetManagerDeleteComponent,
        SpModalAssetManagerEditComponent,
        SpIntroductionComponent,
        SpModalProjectDetailsTagManagerTagDeleteComponent,
        SpModalProjectDetailsTagManagerTagEditComponent,
        SpModalProjectDetailsTagManagerInterestEditComponent,
        SpModalProjectAdvertisingComponent,
        SpModalMapEditComponent,
        SpProjectReportingDeleteWidgetComponent,
        SpDashboardCreationModalComponent,
        SpDashboardDeleteModalComponent,
        SpDashboardTypeOfTabModalComponent,
        SpDashboardTabCreationModalComponent,
        SpDashboardShareLinkModalComponent,
        SpDashboardSavePresetModalComponent,
        SpProjectReportingPersonaSelectionMessageComponent,
        SpProjectReportingPersonaSelectionModalComponent,
        SpMetaTargetingComponent,
    ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SpLayoutModule,
    AppRoutingModule,
    SpLayoutModule,
    HttpClientModule,
    RouterModule,
    MatIconModule,
    HttpClientModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    SpToolsModule,
    MatTooltipModule,
    SpProjectModule,
    SpAccountModule,
    SpAssetManagerModule,
    SpAdminCompanyModule,
    CarouselModule,
    GridsterModule,
    MatCheckboxModule,
    MatTableModule,
    MatTableModule,
    MatSortModule,
    DirectivesModule,
    MatCardModule,
    MatSliderModule,
    SliderModule,
    MatStepperModule,
    NgxSkeletonLoaderModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    SpProjectCreateModule,
    MatSlideToggleModule,
  ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfigService], multi: true },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always', subscriptSizing: 'dynamic'  } },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: environment.recaptchaKey,
        } as RecaptchaSettings,
      },
        CookieService
    ],
    bootstrap: [AppComponent]
})
// @ts-ignore
export class AppModule {}
