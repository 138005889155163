import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterLinkActive} from '@angular/router';

@Component({
  standalone: true,
  templateUrl: './sp-modal-crawl-confirmation.component.html',
  imports: [
    MatTooltipModule,
    RouterLinkActive
  ]
})
export class SpModalCrawlConfirmationComponent {
  creditsLeft: number = 0;
  creditsExtraLeft: number = 0;
  showCreditExtra: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {creditCount: number},
    public dialogRef: MatDialogRef<SpModalCrawlConfirmationComponent>,
    public auth: AuthenticationService
  ) {
    const credits = auth.session.user.company.subscription.credits;
    const creditsExtra = auth.session.user.company.subscription.credits_extra;
    this.creditsLeft = Math.max(credits - data.creditCount, 0);
    this.creditsExtraLeft = credits == 0 ? (creditsExtra - data.creditCount) : (creditsExtra);
    this.showCreditExtra = creditsExtra > 0;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(){
    this.dialogRef.close(true)
  }
}
